import { handleSid } from 'Modules/handleSid';
import { retargetingDebug as debug } from 'Modules/debug.js';
import { DEBUGGER_STATES } from 'Modules/constants.js';
import { retargetingHit } from 'Modules/retargetingHit';
import { getRetargetingData } from 'Modules/utils/getRetargetingData';

import '@iva/refresher';

debug({ message: 'starting', state: DEBUGGER_STATES.START });

window.rc = window.rc || {};
window.rc.retargetingHit = retargetingHit;

const data = getRetargetingData();

debug({ message: 'retargeting got this data:', ...data });

retargetingHit(data, debug);

debug({ message: 'finished', state: DEBUGGER_STATES.DONE });

handleSid();
