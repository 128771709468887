export const getQueryString = (arrayOfQueries) => {
  const reducer = (acc, obj) => {
    const delimiter = ~acc.indexOf('?') ? '&' : '?';
    if (!obj.key || !obj.value) {
      // no empty queries
      return acc;
    }
    return `${acc}${delimiter}${obj.key}=${obj.value}`;
  };
  return arrayOfQueries.reduce(reducer, '');
};
