export const DEBUGGER_STATES = {
  START: 'start',
  DONE: 'done',
  ERROR: 'error',
};
export const COOKIE_NAMES = {
  SID: 'sid',
  CONSENT: 'euconsent-v2',
};
export const RETARGETING_BASE_URL = '//web.archive.org/web/20210623132412/https://c.seznam.cz/retargeting';
export const CONVERSION_BASE_URL = '//web.archive.org/web/20210623132412/https://c.seznam.cz/conv';
export const ZBOZI_BASE_URL = 'https://web.archive.org/web/20210623132412/https://www.zbozi.cz/conversion/js/conv';
