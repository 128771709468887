/**
 *
 * @param {String} rtgUrl URL partner wants to
 * @return {String}
 */
export const getRetargetingUrl = (rtgUrl, debug = () => {}) => {
  const locUrl = window.location.href;

  debug({ message: 'retargeting url comparing these urls:', rtgUrl, locUrl });

  if (!rtgUrl) {
    return locUrl;
  }

  const locDelimiter = ~locUrl.indexOf('?') ? '?' : '#';
  const rtgDelimiter = ~rtgUrl.indexOf('?') ? '?' : '#';
  const baseLoc = locUrl.split(locDelimiter)[0];
  const baseRtg = rtgUrl.split(rtgDelimiter)[0];

  debug({ message: 'base urls:', baseRtg, baseLoc });

  if (baseLoc === baseRtg) {
    return rtgUrl;
  }
  return locUrl;
};
