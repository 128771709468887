export const getRetargetingData = () => {
  const rtgId = window.seznam_retargetingId || window.seznam_retargeting_id || null;

  if (!rtgId || (window.seznam_dispatchedRetargetingIds && ~window.seznam_dispatchedRetargetingIds.indexOf(rtgId))) {
    return null;
  }

  const data = {
    rtgId,
    category: window.seznam_category,
    itemId: window.seznam_itemId,
    pageType: window.seznam_pagetype,
    rtgUrl: window.seznam_rtgUrl,
  };

  return data;
};
