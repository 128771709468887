/**
 * Creates an image to send GET request to target URL
 * @param {String} url URL GET request will be made to
 * @param {Function} debug Function for debugging
 */
export const sendHit = (url, debug = () => {}) => {
  const pixel = new Image();

  pixel.onerror = (error) => {
    debug({ message: 'sendHit failed', url, error });
  };
  pixel.onabort = () => {
    debug({ message: 'sendHit was aborted', url });
  };
  pixel.onload = () => {
    debug({ message: 'sendHit was successfull', url });
  };

  pixel.src = url;
};
