import { sendHit } from './utils/sendHit';
import { getCookieValue } from './utils/getCookieValue';
import { getQueryString } from './utils/getQueryString';
import { getRetargetingUrl } from './utils/getRetargetingUrl';

import { RETARGETING_BASE_URL, COOKIE_NAMES } from './constants';

/**
 * Sends retargeting hit with provided data.
 * @param {Object} conf Data for the retargeting.
 * @param {Function} debug Debugger function from the script calling this function.
 */
export const retargetingHit = (conf, debug = () => {}) => {
  if (!conf || !conf.rtgId) {
    return;
  }

  const { rtgId, category, itemId, pageType, rtgUrl } = conf;

  const euconsentV2 = window.rc && window.rc.internal && getCookieValue(COOKIE_NAMES.CONSENT);

  const sid = getCookieValue(COOKIE_NAMES.SID);

  debug({ message: 'got following retargeting data:', rtgId, category, itemId, pageType, rtgUrl, euconsentV2, sid });

  const queryArray = [
    { key: 'id', value: rtgId },
    { key: 'category', value: category ? encodeURIComponent(category) : null },
    { key: 'itemId', value: itemId ? encodeURIComponent(itemId) : null },
    { key: 'url', value: encodeURIComponent(getRetargetingUrl(rtgUrl, debug)) },
    { key: 'pageType', value: pageType ? encodeURIComponent(pageType) : null },
    { key: 'consent', value: euconsentV2 ? encodeURIComponent(euconsentV2) : null },
    { key: 'dsid', value: sid ? encodeURIComponent(sid) : null },
  ];

  const url = RETARGETING_BASE_URL + getQueryString(queryArray);

  debug({ message: 'hitting retargeting url:', url });

  sendHit(url, debug);

  window.seznam_dispatchedRetargetingIds = window.seznam_dispatchedRetargetingIds || [];
  window.seznam_dispatchedRetargetingIds.push(rtgId);
};
